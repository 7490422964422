<template>
  <v-row justify="center">
    <v-dialog v-model="isSettingsDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Settings</v-card-title>
        <v-card-text>
          <td>
            <v-switch
              v-model="ishereToolsSwitchOn"
              @change="changeHereToolStatus()"
              label="Here tools"
            ></v-switch>
          </td>
          <v-spacer></v-spacer>
          <td>
            <v-switch
              v-model="isToolTipsOnSwitchOn"
              @change="changeIsToolTipsStatus()"
              label="Tooltips"
            ></v-switch>
          </td>
          <v-spacer></v-spacer>
          <td>
            <v-switch
              v-model="isFeatureDataShareOn"
              @change="shareFeatureData()"
              label="Share features"
            ></v-switch>
          </td>
          <v-spacer></v-spacer>
          <td>
            <label id="more-padding-on-right"> Metric </label>
          </td>
          <td>
            <v-switch
              class="bold_font"
              v-model="isImperial"
              label="Imperial"
              @change="changeScaleReading()"
            ></v-switch>
          </td>
          <v-spacer></v-spacer>
          <td>
            <p id="more-padding-on-right">Mock Location</p>
          </td>
          <td>
            <v-switch
              v-model="isGpsMockLocation"
              :label="isGpsMockLocation ? 'On' : 'Off'"
              @change="UpdateMockGPSLocation"
            ></v-switch>
          </td>
          <v-form ref="validateCoordinates" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Latitude"
                  dense
                  v-model="mockLatitude"
                  :disabled="!isGpsMockLocation"
                  :rules="[rules.required, rules.numeric]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Longitude"
                  dense
                  v-model="mockLongitude"
                  :disabled="!isGpsMockLocation"
                  :rules="[rules.numeric, rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import HereUtil from "@/util/here";
import validations from "@/util/validations";
import { stat } from "fs";

export default {
  name: "settings",
  data() {
    return {
      changeUnitSystem: false,
      hereUtil: new HereUtil(),
      mockLatitude: "",
      mockLongitude: "",
      rules: {
        numeric: validations.numeric,
        required: validations.required
      }
    };
  },
  computed: {
    ...mapState({
      isSettingsDialog: state => state.isSettingsDialog,
      configUnitSystem: state => state.home.config.units,
      mapCenter: state => state.home.mapCenter,
      isMockLocationOn: state => state.setting.isMockGpsSwitchOn,
      mockGPSLatitude: state => state.setting.mockLocation.coords.latitude,
      mockGPSLongitude: state => state.setting.mockLocation.coords.longitude,
      ishereToolsOn: state => state.setting.ishereToolsOn,
      isToolTipsOn: state => state.setting.isToolTipsOn,
      isShareFeatureOn: state => state.setting.isShareFeatureOn
    }),
    isImperial: {
      get() {
        return this.configUnitSystem === "imperial" ? true : false;
      },
      set(value) {
        this.changeUnitSystem = value;
      }
    },
    isFeatureDataShareOn: {
      get() {
        return this.isShareFeatureOn;
      },
      set() {}
    },
    isGpsMockLocation: {
      get() {
        return this.isMockLocationOn;
      },
      set() {}
    },
    ishereToolsSwitchOn: {
      get() {
        return this.ishereToolsOn;
      },
      set() {}
    },
    isToolTipsOnSwitchOn: {
      get() {
        return this.isToolTipsOn;
      },
      set() {}
    }
  },
  created() {
    console.log("...Settings Dialog box created");
  },
  mounted() {
    if (this.isMockLocationOn) {
      this.mockLatitude = this.mockGPSLatitude;
      this.mockLongitude = this.mockGPSLongitude;
    }
  },
  methods: {
    close() {
      if (!this.isMockLocationOn) {
        this.mockLatitude = "";
        this.mockLongitude = "";
      } else {
        this.$store.commit("setting/updateMockLocation", {
          mocklat: this.mockLatitude,
          mocklon: this.mockLongitude
        });
      }
      if (!this.isMockLocationOn || this.$refs.validateCoordinates.validate()) {
        this.$store.commit("changeIsSettingsDialogState", false);
      }
    },
    UpdateMockGPSLocation() {
      this.$store.commit("setting/changeMockGPSLocation");
      if (this.isMockLocationOn) {
        this.mockLatitude = this.mapCenter.lat;
        this.mockLongitude = this.mapCenter.lng;
      }
    },
    changeHereToolStatus() {
      this.$store.commit("setting/updateHereToolStatus");
    },
    changeIsToolTipsStatus() {
      this.$store.commit("setting/changeIsToolTipsStatus");
    },
    shareFeatureData() {
      this.$store.commit("setting/changeShareFeatureStatus");
    },
    changeScaleReading() {
      this.$store.commit(
        "home/changeUnitSystemState",
        this.changeUnitSystem ? "imperial" : "metric"
      );
      this.hereUtil.ui.setUnitSystem(
        this.changeUnitSystem
          ? H.ui.UnitSystem.IMPERIAL
          : H.ui.UnitSystem.METRIC
      );
    }
  }
};
</script>
<style>
.text-align-end {
  text-align: justify;
}
#more-padding-on-right {
  padding-right: 10px;
  font-size: 16px;
  font-weight: bolder;
}
.bold_font {
  font-weight: bold;
}
</style>
